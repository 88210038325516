.App {
  width: 100%;
  height: 100%;
  background-color: black;
  color: white;
  margin: 0;
  padding: 0;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Content-container {
    animation: App-logo-spin 0.5s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: translate(-200px);
  }
  to {
    transform: translate(0);
  }
  from {
    transform: translate(20px);
  }
  to {
    transform: translate(0);
  }
}

.Top-bar {
  position: fixed;
  display: flex;
  height: 12%;
  width: 100vw;
  padding: 0;
  margin: 0;
  margin-right: 0;
  border-bottom: 1px solid white;
  align-content: center;
}

.Name-text-container {
  width: 33%;
  position: relative;
  align-self: center;
  padding-left: 25px;
}

.Name-text {
  font: roboto 400 normal 30px 35.15px;
  font-size: 30px;
}

.Name-text-subtitle {
  font: roboto 400 normal 30px 35.15px;
  font-size: 20px;
  font-style: italic;
}

.Top-bar-spacer {
  position: relative;
  width: 33%;
  justify-self: center;
}

.Icons-container {
  width: 33%;
  position: relative;
  display: flex;
  justify-self: right;
  justify-content: right;
  align-self: center;
  align-content: center;
}

.Icons-row{
  justify-content: right;
  justify-self: end;
  align-content: center ;
}

.SM-button {
  display: flex;
  width:64px;
  height: 64px;
  margin: 1px;
  background-color: black;
  justify-content: center;
}

.Top-bar-link {
  width: 48px;
  height: 48px;
  align-self: center;
}

.Top-bar-icon {
  width: inherit;
  height: inherit;
  align-self: center;
}

.Top-bar-icon:hover {
  fill: gray;
  padding-top: 10%;
}

.App-container {
  display: flex;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 12%;
  bottom: 2%;
  align-content: center;
  justify-content: center;
}

.App-container-row {
  display: flex;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  align-content: center;
  overflow: hidden;
  align-self: center;
}

.App-container-col {
  display: flex;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  align-content: center;
  justify-content: center;
}

.Content-container {
  display: flex;
  margin: 0;
  width: 100%;
  height: 100%;
  border-radius: 0px;
  align-self: center;
  background-color: black;
  overflow: hidden;
  justify-content: center;
}

.Content-container:hover .Content-overlay{
  display:none;

}

.Content-overlay {
  position: absolute;
  display: flex;
  width: inherit;
  height: inherit;
  background-color: black;
  opacity: 30%;
  align-content: center;
  justify-content: center;
  border-radius: 12px;
}

.Content-icon-container{
  display: flex;
  width: 100%;
  height: 100%;
  align-content: center;
  justify-content: center;
  border-radius: 12px;
}

.Content-icon {
  position: absolute;
  justify-self: center;
  align-self: center;
}

.Overlay-icon {
  stroke: none;
}

.Content-body {
  display: flex;
  position: relative;
  top: 0.5%;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 2px;
  justify-content: center;
  overflow: scroll;
  justify-self: center;
}

.Content-body img{
  width: 100%;
  height: 100%;
  border-radius: 12px;
  justify-self: center;
}

.Content-description {
  display: flex;
  position: relative;
  width: 100%;
  text-justify: newspaper;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-style: italic;
  justify-self: center;
  justify-content: center;

}

.Creation-post {
  justify-content: center;
  padding-bottom: 25px;
}

.Music-post {
  padding-bottom: 25px;
}

.Post-spacer {
  height: 2%;
}

.Instagram-embed {
  display: flex;
  justify-content: center;
}

.Media-body {
  padding: 0;
  overflow: scroll;
  justify-self: center;
}

.Content-container:hover {
  border-color: gray;
}

.Bottom-bar {
  position: absolute;
  height: 8%;
  padding: 0;
  margin: 0;
  top: 92%;
  background: #606060;
}